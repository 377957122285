import { useEffect, useState, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { propsFiltrosDefault } from '../../interfaces/propsFiltrosDefault'
import { ModalEstadoContext } from '../../contexts/ModalEstado'
import { AuthContext } from '../../contexts/Auth'
import { Button, Loader } from '../Styles'
import ModalMeuPerfil from '../ModalMeuPerfil'
import ModalAssinatura from '../ModalAssinatura'
import avatarDefault from '../../assets/img/avatarDefault.png'
import maisFiltrosImg from '../../assets/img/maisFiltros.png'
import './filtrosDefault.css'

function FiltrosDefault (props: propsFiltrosDefault) {

    const navigate = useNavigate()

    const { exibirModalSecundario, modalEstadoSecundario, modalEstadoTernario } = useContext(ModalEstadoContext)
    const { isAuth } = useContext(AuthContext)

    const [avatar, setAvatar] = useState ('')
    const [dadosUsuario, setDadosUsuario] = useState(false)
    const [modalPerfil, setModalPerfil] = useState(false)
    const [modalAssinatura, setModalAssinatura] = useState(false)

    const perfilUsuario = localStorage.getItem('perfilGesttor')

    const situacoesPermitidasPorPerfil: {[key: string]: string[]} = {
        comercial: [
            'Atrasadas', 
            'Pendentes', 
            'Concluídas', 
            'Concluídos', 
            'Cancelados', 
            'Todos', 
            'Ativos', 
            'Sem Análise', 
            'Em Análise', 
            'Aguardando Retorno do Cliente', 
            'Aguardando Retorno do Suporte',
            'Finalizado',
            'Aceita',
            'Em Desenvolvimento',
            'Recusada',
            'Finalizada',
            'Aguardando Assinatura',
            'Assinado',
            'Cancelado'
        ],
        desenvolvedor: [
            'Atrasadas', 
            'Pendentes', 
            'Concluídas', 
            'Concluídos', 
            'Cancelados', 
            'Todos', 
            'Ativos', 
            'Sem Análise', 
            'Em Análise', 
            'Aguardando Retorno do Cliente', 
            'Aguardando Retorno do Suporte',
            'Finalizado',
            'Aceita',
            'Em Desenvolvimento',
            'Recusada',
            'Finalizada',
            'Aguardando Assinatura',
            'Assinado',
            'Cancelado'
        ],
        suporte: [
            'Atrasadas', 
            'Pendentes', 
            'Concluídas', 
            'Concluídos', 
            'Cancelados', 
            'Todos', 
            'Ativos', 
            'Sem Análise', 
            'Em Análise', 
            'Aguardando Retorno do Cliente', 
            'Aguardando Retorno do Suporte',
            'Finalizado',
            'Aceita',
            'Em Desenvolvimento',
            'Recusada',
            'Finalizada',
            'Aguardando Assinatura',
            'Assinado',
            'Cancelado'
        ],
        consultor: [
            'Atrasadas', 
            'Pendentes', 
            'Concluídas', 
            'Concluídos', 
            'Cancelados', 
            'Todos', 
            'Ativos', 
            'Sem Análise', 
            'Em Análise', 
            'Aguardando Retorno do Cliente', 
            'Aguardando Retorno do Suporte',
            'Finalizado',
            'Aceita',
            'Em Desenvolvimento',
            'Recusada',
            'Finalizada',
            'Aguardando Assinatura',
            'Assinado',
            'Cancelado',
            'Completo',
            'Incompleto'
        ],
        atendimento: [
            'Aguardando Recebimento', 
            'Atrasadas', 
            'Recebimento Parcial', 
            'Pendentes', 
            'Concluídas', 
            'Concluídos', 
            'Cancelados', 
            'Sem Contato', 
            'Em Negociação', 
            'Conquistado', 
            'Perdido',
            'Todos',
            'Ativos', 
            'Inativos', 
            'Sem Análise', 
            'Em Análise', 
            'Aguardando Retorno do Cliente', 
            'Aguardando Retorno do Suporte',
            'Finalizado',
            'Conclúido',
            'Aceita',
            'Em Desenvolvimento',
            'Recusada',
            'Finalizada',
            'Aguardando Assinatura',
            'Assinado',
            'Cancelado',
            'Completo',
            'Incompleto',
            'Finalizados'
        ],
        administrativo: [
            'Aguardando Recebimento', 
            'Atrasadas', 
            'Recebimento Parcial', 
            'Recebidas', 
            'Todas', 
            'Pendentes', 
            'Concluídas', 
            'Ativas', 
            'Inativas', 
            'Concluídos', 
            'Cancelados', 
            'Pagas', 
            'Pagamento Parcial', 
            'Aguardando Pagamento', 
            'Sem Contato', 
            'Em Negociação', 
            'Conquistado', 
            'Perdido', 
            'Todos',
            'Ativos', 
            'Inativos', 
            'Sem Análise', 
            'Em Análise', 
            'Aguardando Retorno do Cliente', 
            'Aguardando Retorno do Suporte',
            'Finalizado',
            'Conclúido',
            'Aceita',
            'Em Desenvolvimento',
            'Recusada',
            'Finalizada',
            'Aguardando Assinatura',
            'Assinado',
            'Cancelado',
            'Completo',
            'Incompleto',
            'Finalizados'
        ],
        master: [
            'Aguardando Recebimento', 
            'Atrasadas', 
            'Recebimento Parcial', 
            'Recebidas', 
            'Todas', 
            'Pendentes', 
            'Concluídas', 
            'Todos', 
            'Ativos', 
            'Inativos', 
            'Ativas', 
            'Inativas', 
            'Concluídos', 
            'Cancelados', 
            'Pagas', 
            'Pagamento Parcial', 
            'Aguardando Pagamento', 
            'Sem Contato', 
            'Em Negociação', 
            'Conquistado', 
            'Perdido', 
            'Sem Análise', 
            'Em Análise', 
            'Aguardando Retorno do Cliente', 
            'Aguardando Retorno do Suporte',
            'Finalizado',
            'Conclúido',
            'Aceita',
            'Em Desenvolvimento',
            'Recusada',
            'Finalizada',
            'Aguardando Assinatura',
            'Assinado',
            'Cancelado',
            'Completo',
            'Incompleto',
            'Finalizados'
        ]
    }

    const situacoesPermitidas = perfilUsuario ? situacoesPermitidasPorPerfil[perfilUsuario] || [] : []

    const exibirDadosUsuario = () => setDadosUsuario(!dadosUsuario)
    const exibirModalPerfil = () => exibirModalSecundario()

    const sair = () => {

        localStorage.removeItem('usuarioGesttorAvatar')
        localStorage.removeItem('perfilGesttor')
        localStorage.removeItem('usuarioGesttor')
        localStorage.removeItem('organizacaoGesttor')
        localStorage.removeItem('authGesttor')
        document.cookie = 'authGesttor= ; expires=true, 01 Jan 2023 00:00:01 GMT'

        isAuth()

        return navigate('/')
    }
    
    useEffect(() => {
        setModalPerfil(modalEstadoSecundario)
    }, [modalEstadoSecundario])

    useEffect(() => {
        setModalAssinatura(modalEstadoTernario)
    }, [modalEstadoTernario])

    useEffect(() => {

        const avatar = () => {

            const avatarImg = localStorage.getItem('usuarioGesttorAvatar')

            if (avatarImg === 'null' || avatarImg === null) {

                return setAvatar(avatarDefault)
            }

            return setAvatar(avatarImg)
        }

        avatar()
    }, [])

    return (

        <section id="fundoContainerFiltrosDefault">
            {modalPerfil && <ModalMeuPerfil/>}
            {modalAssinatura && <ModalAssinatura />}
            <form id='containerEsqueroPesquisa' onSubmit={props.onSubmit}>
                <input
                    id='inputTermoPesquisa'
                    value={props.termo}
                    type="text"
                    placeholder={props.placeholder}
                    onChange={props.onChangeTermo} />

                <select id='selectOpcoesPesquisa' value={props.situacao} onChange={props.onChangeSituacao}>
                    {props.listaSituacao
                        .filter(situacao => situacoesPermitidas.includes(situacao.nome))
                        .map((situacao) => (
                            <option key={situacao.valor} value={situacao.valor}>
                            {situacao.nome}
                            </option>
                        ))}
                </select>

                {props.maisFiltros && <img id='maisFiltrosPesquisa' title='Mais Opções de Filtro' src={maisFiltrosImg} alt='' onClick={props.acaoMaisFiltro}/>}

                <Button type='submit' isLoading ={props.isLoading} cor='filtros'>
                    {!props.isLoading && <>Pesquisar</>}
                    {props.isLoading && <Loader isLoading={props.isLoading}/>}
                </Button>
                {props.tipo && <Button type='button' isLoading = {props.isLoading} cor='filtros' onClick={props.acaoBtn}>
                    {`Nov${props.genero === 'masculino' ? 'o' : 'a'} ${props.tipo?.charAt(0).toUpperCase()}${props.tipo?.substring(1)}`}
                </Button>}
            </form>
            <span id='containerDireitoPesquisa'>
                <img id='avatarUsuarioGesttor' src={avatar} alt='' onClick={exibirDadosUsuario}/>
                {dadosUsuario && <span id='dadosUsarioDefaul'>
                    <p onClick={() => exibirModalPerfil()}>Meu Perfil</p>
                    <p onClick={sair}>Sair</p>
                </span>}
            </span>
        </section>
    )
}

export default FiltrosDefault
