import { useContext, useEffect, useState } from 'react'
import { Button } from '../Styles'
import DivSituacao from '../DivSituacao'
import visualizarCurriculo from "../../assets/img/iconVisualizarCurriculo.png"
import fotoPerfil from "../../assets/img/avatarDefault.png"
import { candidatoListaResposta } from '../../interfaces/BancoTalentos'
import '../../styles/modal.css'
import { ModalEstadoContext } from '../../contexts/ModalEstado'

function ModalDadosCandidato({ candidato }: { candidato: candidatoListaResposta }) {
  const { exibirModal } = useContext(ModalEstadoContext)

  const exibirModalCandidatos = () => exibirModal()

  const [dadosCandidato, setDadosCandidato] = useState(candidato || {})

  const [processando, setProcessando] = useState(false)

  useEffect(() => {
    setProcessando(true)
    setDadosCandidato(candidato);
    setProcessando(false)
  }, [candidato]);

  return (
    <div className='fundoModal'>
      <section className='containerModalCentro'>
        <div className='containerModalCandidato'>
            <span className="novoContainerTitulo">
            <img src={visualizarCurriculo} className='adicionarModalIcone' alt='' />
            <p>Visualizar Curriculo</p>
            </span>
            <section className='formModalNovo'>
            <section>
                <img src={dadosCandidato.avatar ? dadosCandidato.avatar : fotoPerfil} alt="" className='fotoPerfil' />
                <span>
                <label>Nome do Candidato</label>
                <input type="text" defaultValue={dadosCandidato.nomeCompleto} disabled />
                </span>
                <span>
                <label>Data de Nascimento</label>
                <input type="date" defaultValue={dadosCandidato.dataNascimento?.toString()} disabled />
                </span>
                <DivSituacao texto={dadosCandidato.situacaoCadastral} cor={dadosCandidato.situacaoCadastral.toLocaleLowerCase()} />
            </section>
            <section className='novoContainerDados'>
                <span>
                <label>Sobre</label>
                <textarea id="" maxLength={500} className='areaTexto' value={dadosCandidato.sobreMim ? dadosCandidato.sobreMim : "Não preenchido"} disabled></textarea>
                </span>
            </section>
            <section>
                <span>
                <label>E-mail</label>
                <input type="text" defaultValue={dadosCandidato.email} disabled />
                </span>
                <span>
                <label>CPF</label>
                <input type="text" defaultValue={dadosCandidato.cpf} disabled />
                </span>
            </section>
            <section>
                <span>
                <label>Telefone</label>
                <input type="text" defaultValue={dadosCandidato.telefone} disabled />
                </span>
                <span>
                <label>PROFISSÃO</label>
                <input type="text" value={dadosCandidato.profissao ? dadosCandidato.profissao : "Não preenchido"} disabled />
                </span>
            </section>
            <section>
                <span>
                <label>Rua</label>
                <input type="text" value={dadosCandidato.rua ? dadosCandidato.rua : "Não preenchido"} disabled />
                </span>
                <span>
                <label>Numero</label>
                <input type="text" value={dadosCandidato.numero ? dadosCandidato.numero : "Não preenchido"} disabled />
                </span>
                <span>
                <label>Bairro</label>
                <input type="text" value={dadosCandidato.bairro ? dadosCandidato.bairro : "Não preenchido"} disabled />
                </span>
                <span>
                <label>Complemento</label>
                <input type="text" value={dadosCandidato.complemento ? dadosCandidato.complemento : "Não preenchido"} disabled />
                </span>
            </section>
            <section>
                <span>
                <label>CEP</label>
                <input type="text" value={dadosCandidato.cep ? dadosCandidato.cep : "Não preenchido"} disabled />
                </span>
                <span>
                <label>Cidade</label>
                <input type="text" value={dadosCandidato.cidade ? dadosCandidato.cidade : "Não preenchido"} disabled />
                </span>
                <span>
                <label>Estado</label>
                <input type="text" value={dadosCandidato.estado ? dadosCandidato.estado : "Não preenchido"} disabled />
                </span>
                <span>
                <label>Uf</label>
                <input type="text" value={dadosCandidato.uf ? dadosCandidato.uf : "Não preenchido"} disabled />
                </span>
            </section>
            {dadosCandidato.formacaoAcademicas && dadosCandidato.formacaoAcademicas.length > 0 &&
            <section className='novoContainerDados'>
                <span>
                    <label>Formação Acadêmica</label>
                    {dadosCandidato.formacaoAcademicas.map((formacao) => (
                    <>
                    <section>
                        <span>
                        <p>Curso</p>
                        <input type="text" defaultValue={formacao.curso} disabled />
                        </span>
                        <span>
                        <p>Nível</p>
                        <input type="text" defaultValue={formacao.nivel} disabled />
                        </span>
                        <span>
                        <p>Instituição de Ensino</p>
                        <input type="text" defaultValue={formacao.instituicaoEnsino} disabled />
                        </span>
                      </section>
                      <section>
                        <span>
                        <p>Data de Inicio</p>
                        <input type="month" defaultValue={new Date(formacao.dataInicio).toISOString().slice(0, 7)} disabled />
                        </span>
                        <span>
                        <p>Data de Termino</p>
                        <input type="month" defaultValue={new Date(formacao.dataTermino).toISOString().slice(0, 7)} disabled />
                        </span>
                        <span>
                        <p>Situação do curso</p>
                        <input type="text" defaultValue={formacao.situacaoCurso} disabled />
                        </span>
                      </section>
                    </>
                  ))}
                </span>
              </section>}
            {dadosCandidato.cursosComplementares && dadosCandidato.cursosComplementares.length > 0 &&
              <section className='novoContainerDados'>
                <span>
                  <label htmlFor="">Cursos Complementares</label>
                  {dadosCandidato.cursosComplementares.map((cursoComplementar) => (
                    <>
                      <section>
                        <span>
                          <p>Curso</p>
                          <input type='text' defaultValue={cursoComplementar.curso} disabled />
                        </span>
                        <span>
                          <p>Instituição de Ensino</p>
                          <input type='text' defaultValue={cursoComplementar.instituicaoEnsino} disabled />
                        </span>
                        <span>
                          <p>Carga Horaria</p>
                          <input type='text' defaultValue={cursoComplementar.cargaHoraria} disabled />
                        </span>
                      </section>
                      <section>
                        <span>
                          <p>Data de Inicio</p>
                          <input type='month' defaultValue={new Date(cursoComplementar.dataInicio).toISOString().slice(0, 7)} disabled />
                        </span>
                        <span>
                          <p>Data de Termino</p>
                          <input type='month' defaultValue={new Date(cursoComplementar.dataTermino).toISOString().slice(0, 7)} disabled />
                        </span>
                        <span>
                          <p>Situação do Curso</p>
                          <input type='text' defaultValue={cursoComplementar.situacaoCurso} disabled />
                        </span>
                      </section>
                    </>
                    ))}
                </span>
            </section>
            }
             {dadosCandidato.experienciaProfissionals && dadosCandidato.experienciaProfissionals.length > 0 &&
              <section className='novoContainerDados'>
                <span>
                  <label htmlFor="">Experiencia Profissionais</label>
                  {dadosCandidato.experienciaProfissionals.map((experiencia) => (
                    <>
                      <section>
                        <span>
                          <p>Cargo</p>
                          <input type='text' defaultValue={experiencia.cargo} disabled />
                        </span>
                        <span>
                          <p>Empresa</p>
                          <input type='text' defaultValue={experiencia.nomeEmpresa} disabled />
                        </span>
                        <span>
                          <p>Tipo de Contrato</p>
                          <input type='text' defaultValue={experiencia.regimeContratacao} disabled />
                        </span>
                      </section>
                      <section>
                        <span>
                          <p>Data de Inicio</p>
                          <input type='month' defaultValue={new Date(experiencia.dataInicio).toISOString().slice(0, 7)} disabled />
                        </span>
                        <span>
                          <p>Data de Termino</p>
                          <input type='month' defaultValue={new Date(experiencia.dataTermino).toISOString().slice(0, 7)} disabled />
                        </span>
                      </section>
                      <section>
                        <span>
                        <p>Principais Atribuições</p>
                        <textarea className='areaTexto' defaultValue={experiencia.principaisAtribuicoes} disabled></textarea>
                        </span>
                      </section>
                      <hr />
                    </>
                    ))}
                </span>
            </section>
            }
            {dadosCandidato.interesseAreas && dadosCandidato.interesseAreas.length > 0 &&
              <section className='novoContainerDados'>
                <span>
                  <label htmlFor="">Áreas de Interesse</label>
                  {dadosCandidato.interesseAreas.map((area) => (
                    <section>
                      <span>
                        <input type='text' defaultValue={area.descricao} disabled />
                      </span>
                    </section>
                    ))}
                </span>
            </section>
            }
            {dadosCandidato.interesseRegimes && dadosCandidato.interesseRegimes.length > 0 &&
              <section className='novoContainerDados'>
                <span>
                  <label htmlFor="">Regimes de Interesse</label>
                  {dadosCandidato.interesseRegimes.map((regime) => (
                    <section>
                      <span>
                        <input type='text' defaultValue={regime.descricao} disabled />
                      </span>
                    </section>
                    ))}
                </span>
            </section>
            }
            <span className="botoesContainer">
                <Button type='button' cor='cancelar' isLoading={processando} onClick={() => exibirModalCandidatos()}>Fechar</Button>
            </span>
            </section>
        </div>
      </section>
    </div>
  )
}

export default ModalDadosCandidato
